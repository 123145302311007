import React from 'react';
import { Container, Alert } from 'theme-ui';
import PrimaryLayout from '../../components/layout/primary/primary';

const promptPage: React.FC<{location: any}> = ({location}) => (
	<PrimaryLayout>
      <Container>
  	    <Alert> {typeof location.state === `undefined` ? '' : location.state.prompt} </Alert>
      </Container>		
	</PrimaryLayout>
);

export default promptPage;
